<template>
    <div id="newslist">
        <div class="content">
            <div class="breadcrumbs">
                <el-breadcrumb>
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                        {{ item.text }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <ul class="navs">
                <li v-for="(item,index) in subNavs" :key="index" :class="activedIndex === index ? 'actived' : ''">
                    <router-link :to="item.templateUrl">{{ $i18n.locale === 'en' ? item.enName : item.cnName }}</router-link>
                </li>
            </ul>
            <div class="list">
                <ul v-if="list.length > 0">
                    <li v-for="(item, index) in list" :key="index">
                        <div class="pic">
                            <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" />
                            <img :src="require('../../assets/images/article_bg.png')" alt="" class="bg">
                        </div>
                        <h4>{{ isEn ? item.enTitle : item.cnTitle }}</h4>
                        <p>{{ isEn ? item.enContentSummary : item.cnContentSummary }}</p>
                        <i class="icon" @click="toDetail(item)"></i>
                    </li>
                </ul>
                <div class="pagination" v-if="list.length > 0">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="getList"
                        :current-page.sync="pageCurrent"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "News",
        components: {},
        data() {
            return {
                staticPath: staticPath,
                breadcrumbs: [],
                subNavs: [],
                pageCurrent: 1,
                pageSize: 10,
                total: 0,
                list: [],
                activedIndex: 0,
                videoIdx: 0,
                overlay: false,
                contId: '',
                parentId: '',
                isEn: false,

            };
        },
        watch: { // watch 中不能用箭头函数，不然this指向出问题
            "$route.params.page": function(val) {
                this.matchNavs(val);
            },
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            matchNavs(name) {
                this.subNavs.map((item, index) => {
                    if (item.templateUrl.indexOf(name) !== -1) {
                        this.pageCurrent = 1;
                        this.activedIndex = index;
                        this.hasArticle = item.hasArticle;
                        this.navType = item.type;
                        this.contId = item.id;
                        this.getBreadcrumbs();
                        this.getList();
                        return
                    }
                })
            },
            playVideo(index) {
                this.videoIdx = index;
                this.overlay = true;
            },
            getList() {
                const params = {
                    navigationId: this.contId,
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize
                }
                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.list = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getBreadcrumbs() { // 获取面包屑导航列表
                this.$http.get(urls.getBreadcrumb + this.contId).then(res => {
                    if (res.data.status === 200) {
                        const isEn = this.isEn ? true : false;
                        const result = res.data.data;
                        const homePage = {
                            text: isEn ? 'Home' : '首页',
                            pathName: 'Home',
                            disabled: false,
                            href: '/'
                        }
                        this.breadcrumbs = [
                            homePage,
                            {
                                text: isEn ? result[0].enName : result[0].cnName,
                                pathName: result[0].templateUrl,
                                disabled: false,
                                href: result[0].templateUrl
                            }, {
                                text: isEn ? result[1].enName : result[1].cnName,
                                pathName: result[1].templateUrl,
                                disabled: true,
                                href: ''
                            }
                        ]
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message)
                    }
                })
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            getSubNavs() {
                this.$http.get(urls.getSubNavs + this.parentId).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.subNavs = res.data.data;
                        const navName = this.$route.params.page;
                        this.matchNavs(navName);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.contId = window.atob(decodeURIComponent(this.$route.query.n))
            this.parentId = window.atob(decodeURIComponent(this.$route.query.p))

            this.getSubNavs()
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #newslist {
        &::before {
            content: '';
            display: block;
            width: 5.7vw;
            height: 23.4vw;
            background: url("../../assets/images/1097.png") no-repeat center center;
            background-size: 100% 100%;
            position: absolute;
            top: 60vw;
            left: 0;
        }
        .content {
            width: 1200px;
            margin: 0 auto;
            box-sizing: border-box;
            .title {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #ddd;
                margin-bottom: 1vw;
                h2 {
                    color: #34254C;
                    font-size: 1.25vw;
                    +a {
                        color: #A9A6AF;
                        font-size: 0.73vw;
                        position: relative;
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 0.5vw;
                            height: 0.5vw;
                            border: 1px solid #B20000;
                            border-top: 0;
                            border-right: 0;
                            transform: rotate(-135deg);
                            position: absolute;
                            right: 0.4vw;
                            top: 0.45vw;
                        }
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 1vw;
                            height: 1vw;
                            border-radius: 50%;
                            border: 1px solid #A9A6AF;
                            position: relative;
                            top: 0.2vw;
                            margin-left: 0.5vw;
                        }
                    }
                    &::after {
                        content: '';
                        display: block;
                        width: 2em;
                        height: 3px;
                        background: #E30D21;
                        border-radius: 1px;
                        margin-top: 4px;
                    }
                }
            }
            .information {
                display: flex;
                justify-content: space-between;
                padding: 3vw 0;
            }
            @{deep} .breadcrumbs {
                margin: 2vw 0;
                .el-breadcrumb__inner.is-link {
                    color: #606266;
                }
                margin: 2vw 0;
                .link {
                    padding: 0 5px;
                }
                .el-breadcrumb{
                    font-size: 18px;
                    font-weight: normal;
                    .el-breadcrumb__inner a, 
                    .el-breadcrumb__separator,
                    .el-breadcrumb__inner.is-link{
                        font-weight: normal;
                        color:#625F6F;
                    }
                }
            }
            .navs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2vw 0;
                width: 920px;
                li {
                    width: 148px;
                    height: 64px;
                    line-height: 64px;
                    text-align: center;
                    position: relative;

                    a{
                        font-size: 18px;
                        color:#7C7FA2;
                    }

                    &:not(:last-child)::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: -20px;
                        height: 30px;
                        margin-top:-15px;
                        border-right: 1px solid #eaeaea;
                    }
                    &:last-child {
                        border: 0;
                    }
                    &.actived {
                        background: linear-gradient(to bottom, #FF4B7E, #D93C46);
                        border-radius: 6px;
                        box-shadow: 0 0.52vw 1vw rgba(217, 60, 70, 0.8);
                        &::after {
                            border-right-color: #eaeaea;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
            .list {
                width: 1200px;
                margin: auto;
                padding: 2vw 0 5vw;

                >ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 22.75%;
                        background: #fff;
                        line-height: 24px;
                        box-shadow: 0 10px 40px rgba(0, 181, 255, 0.08);
                        margin: 0 3% 4% 0;
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        .pic {
                            position: relative;
                            img {
                                width: 100%;
                                height: 220px;
                            }
                            .bg {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 50px;
                            }
                        }
                        h4 {
                            /*color: #6b6667;
                            padding: 5px 15px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 14px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;*/
                            color: #6b6667;
                            padding: 18px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 24px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            height: 88px;
                        }
                        >p {
                            /*height: 88px;
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 22px;
                            color: #aaaaaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;*/
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 25px;
                            color: #aaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                        >span {
                            display: inline-block;
                            width: 100%;
                            margin: 10px auto;
                            text-align: center;
                            font-size: 1vw;
                            font-weight: bold;
                        }
                        .icon {
                            display: inline-block;
                            width: 1.45vw;
                            height: 1.07vw;
                            background: url("../../assets/images/icon_arrow.png") no-repeat 100% 100%;
                            background-size: contain;
                            cursor: pointer;
                            margin: 10px 20px 20px;
                        }
                    }
                }

            }
            .pagination {
                text-align: center;
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #newslist{

            .content{
                width: 84%;
                @{deep} .breadcrumbs {
                    .el-breadcrumb{
                        .fontFn(12);
                        font-weight: normal;
                    }
                }
                .navs{
                    margin:0;
                    padding:4% 0;
                    display: block;
                    width: 100%;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        width: 33.3%;
                        .fontFn(30,line-height);
                        .fontFn(30,height);
                        a{
                            .fontFn(12);
                        }
                        &:not(:last-child)::after{
                            content:none;
                        }
                    }
                }
                .list{
                    width: 100%;
                    &>ul{
                        li{
                            width: 100%;
                            margin:0 0 6%;
                            .pic{
                                img{
                                    height: auto;
                                }
                            }
                            .icon{
                                .fontFn(20,width);
                                .fontFn(14,height);
                            }
                        }
                    }
                }
            }
        }
    }
</style>